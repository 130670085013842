import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks'
import 'magnific-popup'
import './lib/ortto'
import Headroom from 'headroom.js';
import $ from 'jquery';

const Mangrove = {
  init() {
    this.cache();
    this.checkIE11();
    this.preventFuot();
    this.events();
    this.initHeadroom();
    this.initPopups();
    this.initSplashModal();
    // this.initPreselectForm();
  },
  cache() {
    this.$doc = $(document);
    this.$win = $(window);
    this.$html = $('html');
    this.$body = $('body');
    this.$htmlBody = $('html,body');
    this.$mainWrap = $('main');
    this.$header = $('.js-header');
    this.$mobileNav = $('.js-nav-mobile');
    this.$hamburgerNav = $('.js-nav-hamburger');
    this.$hamburger = $('.js-hamburger');
    this.$filtersNav = $('.js-filters');
  },
  events() {
    this.$doc.on(
      'click',
      '.js-hamburger',
      event => this.showMobileNav(event)
    );
    this.$doc.on(
      'click',
      '.js-show-filters',
      event => this.showFiltersNav(event)
    );
    this.$body.on(
      'click',
      event => this.closeFiltersNav(event)
    );
    this.$doc.on(
      'click',
      '.js-share',
      event => this.openShareWindow(event)
    );
    this.$doc.on(
      'click',
      '.js-search',
      event => this.showSearch(event)
    );
    this.$doc.on(
      'click',
      '.js-popup-video',
      event => this.playVideoItem(event)
    );
    this.$doc.on(
      'click',
      '.js-popup-values',
      event => this.workWithUsValuePopup(event)
    );
    this.$doc.on(
      'click',
      '.js-standard-values',
      event => this.standardValuesPopup(event)
    );
    this.$doc.on(
      'click',
      '.js-form-popup',
      event => this.formTitlePopup(event)
    );
    this.$doc.on(
      'click',
      '.custom-popup__close',
      event => this.workWithUsValuePopup(event)
    );
    this.$doc.on(
      'click',
      '.js-tab',
      event => this.showTab(event)
    );

  },
  initSplashModal(){
    if($(".splash-modal").length){

      if($(".splash-modal").css('display') === 'flex'){
        $("body").addClass("noscroll");
        $(".splash-modal__inner").fadeIn(1300);
      }
      $(".splash-close").on('click', function(){
        $(".splash-modal").hide();
        $("body").removeClass("noscroll");
      });
      $(".splash-modal").on('click', function(){
        $(".splash-modal").hide();
        $("body").removeClass("noscroll");
      });
    }
  },
  checkIE11() {
    const uaString = navigator.userAgent;
    const match = /\b(MSIE |Trident.*?rv:|Edge\/)(\d+)/.exec(uaString);
    if (match && match[2] == 11)
      this.$html.addClass('ie11');
  },
  preventFuot() {
    this.$doc.ready(() => {
      this.$html.removeClass('no-js').addClass('js');
    });
  },
  showTab(event) {
    event.preventDefault();
    let el = $(event.currentTarget)
    let tab = el.attr('href');
    if (tab.length > 0) {
      $('.js-tab').removeClass('is-active');
      $('.tab').removeClass('is-active');
      $(tab).addClass('is-active');
      $(el).addClass('is-active');
    }
  },
  showSearch(event) {
    event.preventDefault();
    this.$header.toggleClass('has-active-search');
  },
  openShareWindow(event) {
    let link = $(event.currentTarget).attr('href');
    if (link.substr(0, 6) != 'mailto') {
      event.preventDefault();
      window.open(link, '', 'width=600, height=400');
    }
  },
  workWithUsValuePopup(event) {
    event.preventDefault();
    if ($(event.target).attr("closePopup")) {
      $(".custom-popup--value").hide();
      return;
    } else {
      var item = $(event.target).closest('a');
      var popupEl = $(item).attr('href');
      var titleHeight = $(item).children(".value-box__title").outerHeight();
      var idValue = $(item).attr('id');
      $(popupEl).css("margin-top", -titleHeight);
      $(".custom-popup--value").hide();
      $(popupEl).slideToggle();
      $([document.documentElement, document.body]).animate({
        scrollTop: $(popupEl).offset().top - 300
      }, 500);
    }
  },
  standardValuesPopup(event) {
    event.preventDefault();
    if ($(event.target).attr("closePopup")) {
      $(".custom-popup--value").hide();
      return;
    } else {
      var item = $(event.target).closest('a');
      var popupEl = $(item).attr('href');
      var titleHeight = $(item).children(".value-box__title").outerHeight();
      var idValue = $(item).attr('id');
      $(".custom-popup--value").hide();
      $(popupEl).fadeIn();
      $([document.documentElement, document.body]).animate({
        scrollTop: $(popupEl).offset().top - 180
      }, 500);
    }
  },
  formTitlePopup(event) {
    event.preventDefault();
    var item = $(event.target).closest('a');
    var popupEl = $(item).attr('href');
    $(".custom-popup--value").hide();
    $(popupEl).fadeIn();
    $(document).ready(function(){
      $(document).on('gform_confirmation_loaded', function(event, formId){
        if( formId === 3 ){
          document.getElementById("downloadLink").click();
        }
      });
    });

    $(".form-popup-inner__close").on('click', function(){
      $(".custom-popup--value").hide();
    });
  },
  initHeadroom() {
    let theHeader = document.querySelector('.js-header');
    let headroom = new Headroom(theHeader, {
      'offset': 100,
      'tolearnce': 5
    });
    headroom.init();
  },
  initPopups() {
    $('.js-popup-team').magnificPopup({
      type: 'inline',
      midClick: true,
      mainClass: 'popup--animate',
    });
  },
  showMobileNav(event) {
    event.preventDefault();
    this.$mobileNav.toggleClass('is-active');
    this.$hamburgerNav.toggleClass('is-active');
    this.$hamburger.toggleClass('is-active');
    this.$htmlBody.toggleClass('has-active-nav');

    if (this.$mobileNav.hasClass('is-active')) {
      this.$hamburger.attr("aria-pressed", "true");
    } else {
      this.$hamburger.removeAttr("aria-pressed");
    }
  },
  showFiltersNav(event) {
    event.preventDefault();
    event.stopPropagation();
    this.$body.toggleClass('has-filters');
    this.$filtersNav.toggleClass('is-active');
  },
  closeFiltersNav(event) {
    if (!$(event.target).closest('.js-filters').length && !$(event.target).hasClass('js-show-filters')) {
      this.$body.removeClass('has-filters');
      this.$filtersNav.removeClass('is-active');
    }
  },
  playVideoItem(event) {
    event.preventDefault();
    const videoItem = $(event.currentTarget);
    const videoType = videoItem.data('video-type');
    const videoSrc = videoItem.data('video-url');

    if (videoType == 'external') {
      $.magnificPopup.open({
        items: {
          src: videoItem.attr('href'),
          type: 'iframe',
        },
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate',
        iframe: {
          // markup: markup, // HTML markup of popup, `mfp-close` will be replaced by the close button
          patterns: {
            youtube: {
              index: 'youtube.com',
              id: 'v=',
              src: '//www.youtube.com/embed/%id%?rel=0&autoplay=1'
            },
            vimeo: {
              index: 'vimeo.com/',
              id: '/',
              src: '//player.vimeo.com/video/%id%?autoplay=1'
            },
          }
        }
      });
    } else if (videoType == 'internal') {
      $.magnificPopup.open({
        items: {
          src: '#video-popup',
          type: 'inline',
        },
        midClick: true,
        removalDelay: 100,
        mainClass: 'popup--animate',
        callbacks: {
          open: function () {
            $('html').css('margin-right', 0);
            $(this.content).find('source').attr('src', videoSrc);
            $(this.content).find('video')[0].load();
            $(this.content).find('video')[0].play();
          },
          close: function () {
            $(this.content).find('video')[0].load();
          }
        }
      });
    } else {
      return false;
    }

  },
  // initPreselectForm() {
  //   if (window.location.href.indexOf("/contact-us/") > -1) {
  //     console.log('this is contact page');
  //     if(document.referrer.indexOf("/bankstandards/") < 0) {
  //       setTimeout(() => {
  //         console.log('that is it');
  //         $("#RESULT_RadioButton-3").value = "Bank Standards Input";
  //       }, 3000);
  //     }
  //   }
  // }
};

Mangrove.init();
